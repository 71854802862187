<template>
	<transition
	    enter-active-class="transitionEnter animated slideInUp faster"
	    leave-active-class="transitionLeave animated slideOutDown faster"
	    mode="out-in"
	    appear
	>
		<div class="night-paradise-parent">
			<div class="bg"></div>
		</div>
	</transition>
</template>

<script>
	// import { mapGetters } from 'vuex';

	export default {
		computed: {
			// ...mapGetters({
				// getActiveTask: 'tasks/getActiveTask',
				// isLobby: 'scenes/isLobby',
			// })
		}
	}
</script>

<style lang="scss" scoped>

	.night-paradise-parent,
	.bg {
		position: absolute;
		top: -10px;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -4; // [LOCKED]
	}

	//-----------------------------------------------------------------
	// WALLPAPER
	//-----------------------------------------------------------------

	.bg {
		// background: #5b5e6a url('./assets/img/bloody-forest.jpg') no-repeat center center;
		background: #5b5e6a url('./assets/img/computers.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/dark-house.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/dark-steps.jpg') no-repeat center center;

		// background: #5b5e6a url('./assets/img/future.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/cinema-test.jpg') no-repeat center center;

		// background: #5b5e6a url('./assets/img/graveyard.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/keys.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/kid-bedroom.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/night-moon.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/night-paradise.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/screens.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/snow-house.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/spider.jpg') no-repeat center center;
		// background: #5b5e6a url('./assets/img/study.jpg') no-repeat center center;

		background-size: cover;
		overflow: hidden;
		// filter: blur(3px);
		// z-index: -1;
	}

	// PAUSE MOTION
	// .is-performance-mode .base-wallpaper .base-wallpaper-icons {
	// 	animation: none;
	// }

</style>